<template>
	<div class="animated fadeIn">
		<b-card>
			<b-card-title><i class="icon-docs"></i> Storage Location Importer</b-card-title>
			<b-card-sub-title>Imports storage location data in bulk</b-card-sub-title>
			<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

			<b-container fluid class="mt-4">
				<b-form @submit.stop.prevent="handleSubmit" novalidate>
					<b-container fluid>
						<div v-show="!importOngoing && !importDone">
							<b-row class="my-12">
								<b-col sm="6">
									<b-form-group label="Company Form:"
										description="Please select a valid json or csv file for storage location import form format.">
										<b-form-file v-model="file" ref="fileinput" @change="loadTextFromFile($event)"
											:state="Boolean(file)" placeholder="Choose a JSON or CSV file"></b-form-file>
									</b-form-group>
									<label>
										Download JSON template
										<a :href="jsonTemplateUrl" download="StorageLocationImporter.json">
											here
										</a>
										and CSV template
										<a :href="csvTemplateUrl" download="StorageLocationImporter.csv">
											here.
										</a>
									</label>
								</b-col>
								<b-col sm="1">
									<b-button variant="primary" class="resetButton"
										@click="startAnotherImport()">Reset</b-button>
								</b-col>
							</b-row>
							<b-row class="my-12">
								<b-col sm="12">
									<b-card v-if="!importOngoing && jsonData !== null" title="Content Preview"
										sub-title="Below is the overview of the content of the storage location import form you have selected">
										<br />
										<json-viewer :value="jsonData" />
										<br />
										<b-button variant="primary" @click="importFile()">Proceed with Import</b-button>
									</b-card>
								</b-col>
							</b-row>
						</div>

						<div v-show="importOngoing || importDone">
							<b-row class="my-12">
								<b-col sm="12">
									<b-card title="Import Status" sub-title>
										<div v-if="importErrors.length > 0">
											<p class="card-text">{{ importResultLog }}</p>
											<ul>
												<li v-for="(item, index) in importErrors" :key="index">
													{{ item }}
												</li>
											</ul>
										</div>

										<div v-else>
											<p class="card-text">
												{{ importStatusDisplay }}
											</p>
										</div>

										<p class="card-text container">
											<loading :active.sync="importOngoing" loader="spinner" color="#20A8D8"
												:is-full-page="false" />
										</p>
										<span v-show="!importOngoing">
											<b-button variant="primary" @click="startAnotherImport()">Start Another
												Import</b-button>
										</span>
									</b-card>
								</b-col>
							</b-row>
						</div>
					</b-container>
				</b-form>
			</b-container>
		</b-card>
	</div>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { ValidationUtil } from '@/utils/validationUtil';
import { ImportUtil } from '@/utils/importUtil';

// API
import dataImporterApi from '@/api/dataImporterApi';

// Others
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
let Papa = require('papaparse');

export default {
	name: 'storage-location-importer',
	components: { Loading },
	data() {
		return {
			currUserId: this.$store.getters.loggedUser.id,

			isLoading: false,
			file: null,

			importOngoing: false,
			importDone: false,
			hasError: false,
			importResultLog: '',
			importErrors: [],

			jsonData: null,
			jsonTemplateUrl: '../../assets/files/StorageLocationImporter.json',
			csvTemplateUrl: '../../assets/files/StorageLocationImporter.csv',
		};
	},
	computed: {
		importStatusDisplay() {
			let statusDisplay = '';

			if (this.importOngoing) {
				statusDisplay = 'Import In-Progress.';
			} else {
				if (this.hasError) {
					statusDisplay = this.importResultLog;
				} else {
					statusDisplay = 'Import Successful! \n' + this.importResultLog;
				}
			}
			return statusDisplay;
		},
	},
	methods: {
		loadTextFromFile(ev) {
			const file = ev.target.files[0];

			if (this.validateFileAfterBrowse(file)) {
				const reader = new FileReader();
				reader.onload = (e) => {
					let extension = file.name.split('.').pop().toLowerCase();
					if (extension === 'csv') {
						const { data } = Papa.parse(e.target.result, {
							header: true,
							skipEmptyLines: true,
						});

						this.jsonData = this.formatJsonData(data, extension);
					} else {
						this.jsonData = JSON.parse(e.target.result);
						this.jsonData = this.formatJsonData(this.jsonData.storageLocations, extension);
					}
				};

				reader.readAsText(file);
			}
		},

		validateFileAfterBrowse(file) {
			let isValid = true;
			const fileTypes = ['csv', 'json'];
			const extension = file.name.split('.').pop().toLowerCase();
			const isAllowed = fileTypes.indexOf(extension) > -1;

			if (!file) {
				this.$toaster.warning(
					'Please select a valid storage location form to proceed.'
				);
				isValid = false;
				this.jsonData = null;
			} else if (!isAllowed) {
				this.$toaster.error(
					'Invalid File Type: Please import a valid storage location form in JSON or CSV format.'
				);
				isValid = false;
				this.jsonData = null;
			}

			return isValid;
		},

		validateFileBeforeImport(storageLocations) {
			let isValid = true;
			storageLocations.forEach((item) => {
				let error = '';

				if (ImportUtil.hasBlankColumnName(item)) {
					error = 'Empty Column Name is not allowed. Please check your column names.';
				} else if (!item.company || item.company.length === 0) {
					error = 'Company is required.';
				} else if (!item.name || item.name.length === 0) {
					error = 'Storage Name is required.';
				} else if (!item.address || item.address === null) {
					error = 'Address is required.';
				} else if (
					!item.geoaddress.latitude ||
					item.geoaddress.latitude.length === 0
				) {
					error = 'Latitude is required.';
				} else if (
					!item.geoaddress.longitude ||
					item.geoaddress.longitude.length === 0
				) {
					error = 'Longitude is required';
				} else if (!item.areaRadius || item.areaRadius.length === 0) {
					error = 'Area Radius is required.';
				}

				if (error != '') {
					this.$toaster.warning(error);
					isValid = false;
				}
			});

			return isValid;
		},

		async importFile() {
			try {
				let storageLocationsArr = this.jsonData.storageLocations;
				if (storageLocationsArr.length === 0) {
					this.$toaster.warning(
						'At least 1 storage location is required per import.'
					);
					return;
				} else if (storageLocationsArr.length > 5000) {
					this.$toaster.warning(
						'Only maximum of 5,000 storage locations is allowed per import.'
					);
					return;
				} else if (ValidationUtil.hasDuplicates(storageLocationsArr, 'name')) {
					let duplicates = ValidationUtil.getDuplicates(
						storageLocationsArr,
						'name'
					);
					this.$toaster.warning(
						'Duplicates detected! Please address the following storage location(s): ' +
						JSON.stringify(duplicates)
					);
					return;
				} else if (!this.validateFileBeforeImport(storageLocationsArr)) {
					return;
				}

				await this.handleSubmit(this.jsonData);
			} catch(error) {
				this.$toaster.warning('The imported storage location data is invalid and cannot be processed');
			}			
		},

		async handleSubmit(json) {
			this.importOngoing = true;
			this.importDone = false;

			let param = {
				currUserId: this.currUserId,
				currTimeStamp: DateUtil.getCurrentTimestamp(),
				storageLocations: json.storageLocations,
			};

			try {
				let { data } = await dataImporterApi.importStorageLocations(param);

				this.hasError = !data.isSuccess;
				this.importErrors = data.errors;
				this.importResultLog = data.message;
				this.importDone = true;
				this.importOngoing = false;

				// update store
				let storageLocationsObj = data.storageLocations;
				this.$store.dispatch('updateAllStorageLocations', storageLocationsObj);
			} catch (error) {
				this.hasError = true;
				this.importResultLog = error;
				this.importDone = true;
				this.importOngoing = false;
			}
		},

		startAnotherImport() {
			this.importDone = false;
			this.importOngoing = false;
			// reset form fields
			this.$refs.fileinput.reset();
			this.file = null;
			this.jsonData = null;
		},

		formatJsonData(params, extension) {
			let results = [];
			params.forEach((param) => {
				param = ImportUtil.trimWhiteSpaces(param);

				if (extension === 'csv') {
					param.geoaddress = {
						latitude: 0,
						longitude: 0,
					};

					if (param.latitude) {
						param.geoaddress.latitude = parseFloat(param.latitude);
						delete param.latitude;
					}
					if (param.longitude) {
						param.geoaddress.longitude = parseFloat(param.longitude);
						delete param.longitude;
					}
				}

				results.push(param);
			});

			return {
				storageLocations: results,
			};
		},
	},
};
</script>
